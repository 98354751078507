import React, { useContext, useState } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"
import Img from "gatsby-image"
import sanitizeHtml from "sanitize-html"

import Hero from "../../../Layout/Hero"
import Container from "../../../Layout/Container"
import useAboutImages from "../hooks/useAboutImages"
import ctas from "../../utils/homeCTA.json"
import styles from "../../../Elements/utils/elements.module.scss"
import { PatientEnrollmentContext } from "../../../Enrollment/EnrollmentContext/PatientEnrollmentContext"
import { DoctorEnrollmentContext } from "../../../Enrollment/EnrollmentContext/DoctorEnrollmentContext"
import LandingPageEnrollment from "../../../Elements/LandingPageEnrollment"
import Button from "../../../Elements/Button"
import { getSignedInUser, hasSignedInUser } from "../../../Auth/services/user"

const AboutHeader = ({ type, module }) => {
  const data = useAboutImages()
  const { patientEnrollmentState, patientEnrollmentDispatch } = useContext(
    PatientEnrollmentContext
  )
  const { doctorEnrollmentState, doctorEnrollmentDispatch } = useContext(
    DoctorEnrollmentContext
  )
  const [moduleState, moduleDispatch] =
    type === "doctor"
      ? [doctorEnrollmentState, doctorEnrollmentDispatch]
      : [patientEnrollmentState, patientEnrollmentDispatch]

  const doctor = data?.doctor_landing?.childImageSharp?.fluid
  const patient = data?.patient_landing?.childImageSharp?.fluid
  const hex_bg = data?.hex_bg?.childImageSharp?.fluid?.src

  const optionForSup = {
    allowedTags: ["sup"],
    allowedAttributes: {
      br: ["class"],
    },
  }

  return (
    <Hero
      size="medium"
      background={{
        image: hex_bg,
        position: "right center",
        size: "cover",
      }}
      flexOverride={true}
      showOverflow={true}
    >
      <Container desktop={10} fullhd={8}>
        <div className="mr-0">
          <h1
            className="title has-text-primary "
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(addSupTags(module?.title), optionForSup),
            }}
          />

          <p className="mb-2">{module?.enroll?.label} </p>
          {type === "patient" ? (
            !hasSignedInUser() ? (
              <LandingPageEnrollment
                enrollmentLink={`/verify-email`}
                moduleDispatch={moduleDispatch}
                moduleState={moduleState}
                type={type}
              />
            ) : (
              <Button
                color="secondary"
                onClick={() => navigate("/epharmacy/upload")}
              >
                Order Medicines
              </Button>
            )
          ) : (
            <Button
              color="secondary"
              onClick={() => navigate("/doctor/enroll")}
            >
              Enroll as Doctor
            </Button>
          )}
        </div>
        <div
          className="is-absolute is-bottom is-hidden-touch"
          style={{
            width: "100%",
            maxWidth: "668px",
            right: "-5%",
          }}
        >
          <Img fluid={type === "patient" ? patient : doctor} />
        </div>
        <div
          className="is-absolute is-bottom is-hidden-mobile is-hidden-desktop"
          style={{
            width: "100%",
            maxWidth: "394px",
            right: "-5%",
          }}
        >
          <Img fluid={type === "patient" ? patient : doctor} />
        </div>
        <div className="is-hidden-tablet" style={{ height: "120px" }}>
          <div
            className="is-absolute is-bottom"
            style={{
              width: "100%",
              maxWidth: "250px",
              right: "-5%",
            }}
          >
            <Img fluid={type === "patient" ? patient : doctor} />
          </div>
        </div>
      </Container>
    </Hero>
  )
}

const addSupTags = (checkString) => {
  return checkString.replace("Saxenda®", "Saxenda<sup>®</sup>")
}

export default AboutHeader
