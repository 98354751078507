import React, { Fragment } from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"

import Hero from "../../../Layout/Hero"
import Container from "../../../Layout/Container"
import MediaBox from "../../../Elements/MediaBox"
import Button from "../../../Elements/Button"

import registrationSteps from "../utils/registrationSteps.json"
import useAboutImages from "../hooks/useAboutImages"

const AboutRegistrationSteps = ({ type, module }) => {
  const data = useAboutImages()
  const rounded_rectangle = data?.rounded_rectangle?.childImageSharp?.fixed
  const benefitRegistration = registrationSteps[type]

  return (
    <Hero size="small">
      <div
        className="is-absolute is-hidden-tablet"
        style={{ zIndex: "-1", left: "-180px" }}
      >
        <Img fixed={rounded_rectangle} />
      </div>
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-3 title has-text-centered has-text-left-mobile  has-text-primary">
          {benefitRegistration?.header}
        </h3>
        <div className="columns is-multiline is-centered mb-3">
          {benefitRegistration?.steps?.map((step, index) => {
            return (
              <Fragment>
                <MediaBox
                  title={step?.title}
                  contentSpacing={"start"}
                  forceMobile={true}
                  isStep={true}
                  isNotLast={benefitRegistration?.steps.length !== index + 1}
                  image={{
                    src: data[step?.imageName]?.childImageSharp?.fixed?.src,
                    alt: step.imageName,
                    width: "72px",
                  }}
                  className="is-align-items-center"
                >
                  <p className="has-text-left">{step?.description}</p>
                </MediaBox>
              </Fragment>
            )
          })}
        </div>
      </Container>
    </Hero>
  )
}

export default AboutRegistrationSteps
