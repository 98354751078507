import React, { Fragment } from "react"
import Container from "../../../Layout/Container"

import Hero from "../../../Layout/Hero"
import MediaBox from "../../../Elements/MediaBox"

import useAboutImages from "../hooks/useAboutImages"
import benefits from "../utils/programBenefits.json"

const AboutBenefits = ({ type }) => {
  const data = useAboutImages()
  const benfitType = benefits[type]

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <div className="is-hidden-tablet" style={{ height: "180px" }}></div>
        <h3 className="my-3 title has-text-centered has-text-left-mobile  has-text-primary">
          {benfitType?.header}
        </h3>
        <div className="columns is-centered pb-4">
          {benfitType?.benefits?.map((benefit, index) => (
            <Fragment>
              <MediaBox
                image={{
                  ...data[benefit?.imageName]?.childImageSharp?.fixed,
                  alt: benefit.imageName,
                }}
                contentSpacing={"center"}
              >
                <p
                  className="has-text-centered has-text-left-mobile"
                  dangerouslySetInnerHTML={{ __html: benefit.description }}
                ></p>
              </MediaBox>
            </Fragment>
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default AboutBenefits
