import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./utils/layout.module.scss"

/**
 * An imposing hero banner to showcase something
 * @param {string} color options: primary, success, info, danger, light, dark
 * @param {string} size options: medium, large, fullheight
 * @param {boolean} isCentered
 */

const Hero = ({
  children,
  color,
  size,
  background,
  isCentered,
  className,
  backgroundComponent,
  style,
  flexOverride = false,
  showOverflow = false,
}) => {
  return (
    <section
      className={classNames(
        `hero is-${color} is-${size} is-relative`,
        {
          "has-text-centered": isCentered,
        },
        className
      )}
      style={{
        ...style,
        overflow: "clip",
        backgroundImage: `url(${background?.image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: background?.size || "contain",
        backgroundPosition: background?.position,
        overflow: showOverflow ? "visible" : "hidden",
      }}
    >
      <div
        className={classNames("hero-body")}
        style={{
          display: flexOverride ? "block" : "flex",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
      {backgroundComponent && (
        <div className={classNames(styles["hero"])}>{backgroundComponent}</div>
      )}
    </section>
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
}

export default Hero
