import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

import Layout from "../../Layout"
import Hero from "../../Layout/Hero"
import Container from "../../Layout/Container"
import AboutHeader from "./components/AboutHeader"
import AboutMechanics from "./components/AboutMechanics"

import {
  GATSBY_BRIEFING_GUIDE_LINK,
  GATSBY_IS_AUTH_ACTIVATED,
} from "gatsby-env-variables"

import AboutBenefits from "./components/AboutBenefits"
import AboutRegistrationSteps from "./components/AboutRegistrationSteps"
import { hasSignedInUser } from "../../Auth/services/user"
import { navigate } from "gatsby"

const AboutPage = ({ pageContext }) => {
  const { module } = pageContext

  useEffect(() => {
    if (GATSBY_IS_AUTH_ACTIVATED && hasSignedInUser()) navigate("/")
  }, [])

  return (
    <Layout
      seoTitle={module?.seoTitle}
      pageContext={pageContext}
      display={{ bottomMargin: false, footer: true }}
    >
      <AboutHeader type={module?.name} module={module} />
      <AboutBenefits type={module?.name} />
      <AboutRegistrationSteps type={module?.name} />
      <AboutMechanics type={module?.name} module={module} />
    </Layout>
  )
}

export default AboutPage
