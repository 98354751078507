import React, { useContext, useEffect, useState } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import { Link, navigate } from "gatsby"

import FormInput from "elements/Form/FormInput"
import FormCheckbox from "elements/Form/FormCheckbox"
import Button from "elements/Button"
//import { EnrollmentContext } from "../Enrollment/EnrollmentContext/EnrollmentContext"

import { firebaseApi } from "services/firebaseApi"
import { buildSessionDocument } from "./utils/buildSessionDocument"
import { generateJWT } from "services/jwt"
//import { isBrowser } from "services/general"

import styles from "./utils/elements.module.scss"
import { AuthContext } from "../../context/AuthContext"
import { checkIfEmailAlreadyExists } from "../Auth/services/signup"
import { GATSBY_IS_AUTH_ACTIVATED } from "gatsby-env-variables"
const landingPageConsentBlurb = `<span class="is-inline-block ml-1">I agree to the program's <a href="/terms-and-conditions" class="has-text-secondary has-text-weight-bold is-underlined" target="_blank" ref="noreferrer noopener">terms and conditions.</a></span>`

const LandingPageEnrollment = ({
  enrollmentLink,
  location,
  type,
  moduleState,
  moduleDispatch,
}) => {
  const [loading, setLoading] = useState(false)
  const { authState, authDispatch } = useContext(AuthContext)

  const checkIfMobileNumber = (values, contactDetails) => {
    if (contactDetails.match(/^09[0-9]{9}$/) && contactDetails.length == 11) {
      moduleDispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...values,
          email: "",
          mobileNumber: contactDetails.substring(contactDetails.indexOf("9")),
        },
      })
      return true
    }
    return false
  }

  const checkIfEmail = (values, contactDetails) => {
    if (
      contactDetails
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      moduleDispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...values,
          email: contactDetails,
          mobileNumber: "",
        },
      })
      return true
    }

    return false
  }

  const handleSubmitLandingPageEnrollment = async (values, { setErrors }) => {
    let contactDetails = values?.contactDetails
    let consentLandingPage = values?.consentLandingPage
    let errorMessage = ""

    if (!contactDetails) errorMessage = "This field is required"
    else if (
      !checkIfMobileNumber(values, contactDetails) &&
      !checkIfEmail(values, contactDetails)
    )
      errorMessage = "Please input a valid mobile number or email"

    if (!!errorMessage) {
      setErrors({
        contactDetails: errorMessage,
      })
      return values
    } else {
      if (consentLandingPage.length === 0) {
        return setErrors({
          consentLandingPage: "This field is required",
        })
      }

      setLoading(true)
      const JWT_OBJECT = await generateJWT()
      const ACCESS_TOKEN = JWT_OBJECT?.data?.access_token
      let request = await firebaseApi({
        accessToken: ACCESS_TOKEN,
      }).post(`sessions`, {
        fields: {
          ...buildSessionDocument({
            values: {
              ...values,
              mobileNumber: checkIfMobileNumber(values, contactDetails)
                ? contactDetails
                : "",
              email: checkIfEmail(values, contactDetails) ? contactDetails : "",
            },
            location,
          }),
        },
      })

      if (!GATSBY_IS_AUTH_ACTIVATED) {
        moduleDispatch({
          type: "SAVE_CONTEXT_TO_SESSION",
          payload: {
            ...values,
            mobileNumber: checkIfMobileNumber(values, contactDetails)
              ? contactDetails
              : "",
            email: checkIfEmail(values, contactDetails) ? contactDetails : "",
          },
        })

        return navigate("/patient/enroll")
      }

      let isEmail = checkIfEmail(values, contactDetails.trim())
      if (isEmail) {
        const isAlreadyEnrolled = await checkIfEmailAlreadyExists(
          contactDetails.trim()
        )

        authDispatch({
          type: "SAVE_CONTEXT_TO_SESSION",
          payload: {
            email: contactDetails.trim(),
            hasBeenVerified: true,
          },
        })

        if (isAlreadyEnrolled) {
          navigate("/sign-in")
        } else {
          navigate("/sign-up")
        }
      }

      setLoading(false)
    }
  }
  return (
    <div
      className={classNames(
        "card has-text-primary p-2 p-1-mobile",
        styles["landingPage__card"]
      )}
    >
      <Formik
        initialValues={{
          consentLandingPage: [],
          ...moduleState,
          contactDetails:
            (moduleState?.email !== "" && moduleState?.email) ||
            (moduleState?.mobileNumber !== "" &&
              moduleState?.mobileNumber &&
              `0${moduleState?.mobileNumber}`) ||
            "",
        }}
        onSubmit={handleSubmitLandingPageEnrollment}
        //validationSchema={formSchema}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            {/* Input: mobile number or email field */}
            <FormInput
              className={classNames(styles["landingPage__input"], "mb-0")}
              name="contactDetails"
              label=""
              isRequired
              helperClassName="is-hidden"
              hasSpace={false}
              onChange={(event) => {
                // moduleDispatch({
                //   type: `SAVE_CONTACT_DETAILS`,
                //   payload: event.target.value,
                // })
              }}
              placeholder={"Enter your email address or mobile number"}
              id="landingPageEnrollmentInput"
              tabIndex={0}
            />

            {/* Consent checkbox */}
            <FormCheckbox
              className={classNames(styles["landingPage__consent"])}
              name="consentLandingPage"
              values={values.consentLandingPage}
              options={[landingPageConsentBlurb]}
              onChange={
                (event) => {}
                // moduleDispatch({
                //   type: `SAVE_AGREE_CONSENT_LANDING_PAGE_ENROLLMENT`,
                //   payload: event.target.checked
                //     ? [landingPageConsentBlurb]
                //     : [],
                // })
              }
              isRequired
            />
            <div className="is-flex is-justify-content-center mt-2">
              <Button
                className={classNames(
                  "has-text-weight-bold",
                  styles["landingPage__button"]
                )}
                type="submit"
                isLoading={loading}
                color="secondary"
              >
                Enroll as {type.charAt(0).toUpperCase() + type.slice(1)}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {type === "patient" && (
        <>
          <hr className="has-background-grey-lighter" />
          <p className={classNames("has-text-centered helper")}>
            Already enrolled?{" "}
            {GATSBY_IS_AUTH_ACTIVATED ? (
              <Link
                className="has-text-weight-bold has-text-secondary is-underline"
                to={"/sign-in"}
              >
                Sign In
              </Link>
            ) : (
              <Link
                className="has-text-weight-bold has-text-secondary is-underline"
                to={"/epharmacy/upload"}
              >
                Order Medicines
              </Link>
            )}
          </p>
        </>
      )}
    </div>
  )
}

export default LandingPageEnrollment
