import React from "react"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * A box section used in colums
 * @param {children}
 * @param {string} title string
 * @param {object} image settings for the image (src, alt)
 * @param {object} contentSpacing settings for the image (src, alt)
 */

const MediaBox = ({
  image,
  title,
  children,
  contentSpacing,
  forceMobile,
  isStep,
  isNotLast,
}) => {
  const img = (
    <img
      src={image?.src}
      alt={`${image?.alt}`}
      className={classNames(styles["mediaBox__image"])}
      width={image?.width || "50%"}
    />
  )

  return (
    <div
      className={classNames(
        `column ${
          forceMobile
            ? "is-12-fullhd is-12-desktop is-12-tablet "
            : "is-4-fullhd is-4-desktop"
        } px-2 px-0-mobile pb-1 has-text-centered columns is-multiline is-mobile`,
        styles["mediaBox"]
      )}
    >
      <div
        className={classNames(
          `column is-4-mobile px-0 ${
            forceMobile && "is-full-tablet is-full-desktop"
          }`,
          styles["mediaBoxImageContainer"],
          forceMobile
            ? "is-4 is-flex is-justify-content-center is-align-content-center"
            : "is-4-mobile is-full-tablet"
        )}
      >
        {isStep ? (
          <div
            className={
              isNotLast
                ? styles[`mediaBoxImageOuterContainer`]
                : styles[`mediaBoxImageOuterContainerLast`]
            }
          >
            <div className={styles["mediaBoxImageInnerContainer"]}>{img}</div>
          </div>
        ) : (
          img
        )}
      </div>
      <div
        className={`column is-flex is-flex-direction-column is-justify-content-${contentSpacing}`}
      >
        {title && (
          <h3
            className={classNames(
              styles["mediaBox__title"],
              forceMobile
                ? "has-text-left"
                : "has-text-left-mobile has-text-centered"
            )}
          >
            {title}
          </h3>
        )}
        {children}
      </div>
    </div>
  )

  //: (
  //   <div
  //     className={classNames(
  //       "column is-4-fullhd is-4-desktop px-2 px-0-mobile pb-1 has-text-centered columns is-multiline is-mobile",
  //       styles["mediaBox"]
  //     )}
  //   >
  //     <ImageContainer />
  //     <div
  //       className={`column is-flex is-flex-direction-column is-justify-content-${contentSpacing}`}
  //     >
  //       {title && (
  //         <h3
  //           className={classNames(
  //             styles["mediaBox__title"],
  //             "is-block has-text-centered has-text-left-mobile"
  //           )}
  //         >
  //           {title}
  //         </h3>
  //       )}
  //       {children}
  //     </div>
  //   </div>
  // )
}

export default MediaBox
