import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Hero from "../../../Layout/Hero"
import Container from "../../../Layout/Container"

import styles from "../../../Elements/utils/elements.module.scss"

import useAboutImages from "../hooks/useAboutImages"

const AboutMechanics = ({ type, module }) => {
  const data = useAboutImages()
  const hex_bg = data?.hex_bg?.childImageSharp?.fluid?.src

  const optionForSup = {
    allowedTags: ["sup"],
    allowedAttributes: {
      br: ["class"],
    },
  }

  return (
    <Hero
      color="white"
      size="small"
      background={{
        image: hex_bg,
        position: "right center",
        size: "cover",
      }}
      flexOverride={true}
    >
      <Container isCentered desktop={10} fullhd={8}>
        <h3 className="my-2 title has-text-primary has-text-centered">
          Mechanics
        </h3>
        <div className="is-centered has-text-centered mb-2">
          <p className="my-2"></p>
          <Link
            to={"#"}
            className={classNames(
              `button-size mb-1 has-text-secondary px-4 px-1-mobile ${styles["secondary_buttonOutline"]}`
            )}
          >
            Download Briefing Guidelines
          </Link>
        </div>
      </Container>
    </Hero>
  )
}

const addSupTags = (checkString) => {
  return checkString.replace("Saxenda®", "Saxenda<sup>®</sup>")
}
export default AboutMechanics
